export const categories = [
  {
    name: "Conference",
    description: "A gathering of experts to share knowledge and discuss a particular topic.",
    icon: "conference-icon.png"
  },
  {
    name: "Comedy",
    description: "Comedy shows that guarantee a bellyaching good time for everyone.",
    icon: "conference-icon.png"
  },
  {
    name: "Art Exhibition",
    description: "A display of artistic works such as paintings, sculptures, or installations.",
    icon: "art-exhibition-icon.png"
  },
  {
    name: "Sports Games",
    description: "A competitive match or contest between two or more sports teams.",
    icon: "sports-game-icon.png"
  },
  {
    name: "Workshops",
    description: "A hands-on session where participants learn and practice specific skills.",
    icon: "workshop-icon.png"
  },
  {
    name: "Charity Gala",
    description: "A formal event aimed at raising funds for a charitable cause.",
    icon: "charity-gala-icon.png"
  },
  {
    name: "Fundraiser",
    description: "An events and campaign to raise money and other kinds of donations for an organization.",
    icon: "charity-gala-icon.png"
  },
  {
    name: "Film Screening",
    description: "A showing of a movie or a selection of films for an audience.",
    icon: "film-screening-icon.png"
  },
  {
    name: "Food",
    description: "Satisfy your taste buds with a symphony of mouthwatering culinary experiences.",
    icon: "food-festival-icon.png"
  },
  {
    name: "Music Event",
    description: "Immerse yourself in the magic of live music and unforgettable performances.",
    icon: "food-festival-icon.png"
  },
  {
    name: "Networking",
    description: "An opportunity for professionals to connect and expand their network.",
    icon: "networking-event-icon.png"
  },
  {
    name: "Fashion Show",
    description: "A runway presentation of clothing and fashion accessories.",
    icon: "fashion-show-icon.png"
  },
  {
    name: "Church",
    description: "Engage in worship, meaningful teachings, and enriching spiritual retreats",
    icon: "fashion-show-icon.png"
  },
  {
    name: "Lectures",
    description: "Expand your horizons through captivating lectures by experts in various fields",
    icon: "fashion-show-icon.png"
  },
  {
    name: "Kids",
    description: "Discover enriching events where kids can explore, learn, and make new friends.",
    icon: "fashion-show-icon.png"
  },
  {
    name: "Online",
    description: "Experience the convenience of attending events from the comfort of home.",
    icon: "fashion-show-icon.png"
  }
].sort((a, b) => {
  if(a.name < b.name) { return -1; }
  if(a.name > b.name) { return 1; }
  return 0;
});

export const ticketTypes = [
  "Early-bird",
  "General",
  "VIP",
  "VVIP",
  "Backstage",
  "Coolerbox"
];

export const southAfricanProvinces = [
  {
    name: "Eastern Cape",
    image: "eastern-cape.jpg"
  },
  {
    name: "Free State",
    image: "free-state.jpg"
  },
  {
    name: "Gauteng",
    image: "gauteng.jpg"
  },
  {
    name: "KwaZulu-Natal",
    image: "kwazulu-natal.jpg"
  },
  {
    name: "Limpopo",
    image: "limpopo.jpg"
  },
  {
    name: "Mpumalanga",
    image: "mpumalanga.jpg"
  },
  {
    name: "Northern Cape",
    image: "northern-cape.jpg"
  },
  {
    name: "North West",
    image: "north-west.jpg"
  },
  {
    name: "Western Cape",
    image: "western-cape.jpg"
  }
];

export const serviceProviders = [
  { name: "Music Artist", image: "https://example.com/sound-engineer-image.jpg" },
  { name: "Host", image: "https://example.com/sound-engineer-image.jpg" },
  { name: "Event Promoter", image: "https://example.com/sound-engineer-image.jpg" },
  { name: "Venue", image: "https://example.com/sound-engineer-image.jpg" },
  { name: "Sound Engineer", image: "https://example.com/sound-engineer-image.jpg" },
  { name: "Choir", image: "https://example.com/sound-engineer-image.jpg" },
  { name: "Lighting Technician", image: "https://example.com/lighting-technician-image.jpg" },
  { name: "Stage Manager", image: "https://example.com/stage-manager-image.jpg" },
  { name: "Event Coordinator", image: "https://example.com/event-coordinator-image.jpg" },
  { name: "DJ (Disc Jockey)", image: "https://example.com/dj-image.jpg" },
  { name: "Event Planner", image: "https://example.com/event-planner-image.jpg" },
  { name: "Live Band", image: "https://example.com/live-band-image.jpg" },
  { name: "Audio Visual Technician", image: "https://example.com/audio-visual-technician-image.jpg" },
  { name: "Production Assistant", image: "https://example.com/production-assistant-image.jpg" },
  { name: "Talent Agent", image: "https://example.com/talent-agent-image.jpg" },
  { name: "Master of Ceremonies (MC)", image: "https://example.com/master-of-ceremonies-image.jpg" },
  { name: "Visual Effects Artist", image: "https://example.com/visual-effects-artist-image.jpg" },
  { name: "Costume Designer", image: "https://example.com/costume-designer-image.jpg" },
  { name: "Set Designer", image: "https://example.com/set-designer-image.jpg" },
  { name: "Choreographer", image: "https://example.com/choreographer-image.jpg" },
  { name: "Makeup Artist", image: "https://example.com/makeup-artist-image.jpg" },
  { name: "Event Photographer", image: "https://example.com/event-photographer-image.jpg" },
  { name: "Videographer", image: "https://example.com/videographer-image.jpg" },
  { name: "Special Effects Coordinator", image: "https://example.com/special-effects-coordinator-image.jpg" },
  { name: "Projectionist", image: "https://example.com/projectionist-image.jpg" },
  { name: "Event Security", image: "https://example.com/event-security-image.jpg" },
  { name: "Event Marketer", image: "https://example.com/event-marketer-image.jpg" },
  { name: "Event Decorator", image: "https://example.com/event-decorator-image.jpg" },
  { name: "Event Caterer", image: "https://example.com/event-caterer-image.jpg" },
  { name: "Event Bartender", image: "https://example.com/event-bartender-image.jpg" }
].sort((a, b) => {
  if(a.name < b.name) { return -1; }
  if(a.name > b.name) { return 1; }
  return 0;
});
