import { useContext, useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import NavBar from "../../components/organisms/navBar"
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../context";
import Header from "../../components/organisms/header";


const StartPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useTitle("Get started")

  if(user) {
    navigate("/")
    return <></>
  }

  return <div>
    <NavBar/>
    <main>
      <Header title={"Get Started"}/>
      <StartForm/>
    </main>
    <Footer/> 
  </div>
}

const StartForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const email = data.get('email')
    const password = data.get('password')

    if(!email || !password) {
      setMessage("Fill in all fields")
      return
    }

    setLoading(true)
    setMessage(null)
    
    signInWithEmailAndPassword(auth, email, password).then((user)=> {
      navigate("/")
    }).catch((error)=>{
      setLoading(false)
      setMessage("Invalid credentials")
    })
  }

  return <section class="contact-section">
    <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="contact-title">Login</h2>
      </div>
      <div class="col-lg-7">
        <form class="form-contact contact_form" onSubmit={handleSubmit} method="post" id="contactForm" novalidate="novalidate">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <input class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your email'" placeholder="Enter your email"/>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <input class="form-control valid" name="password" id="password" type="password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your password'" placeholder="Enter your password"/>
              </div>
            </div>
          </div>
          { message && 
            <div class="col-12" style={{background: "#EEE9E9"}}>
            <div class="form-group">
              <p>{message}</p>
            </div>
          </div>
          }
          <div class="form-group mt-3">
            <button type="submit" class="genric-btn danger large" disabled={loading}>{loading? "Logging in":"Login"}</button>
          </div>
        </form>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <Link to="/register" class="genric-btn primary">Create an account</Link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <Link to="/forgot" class="genric-btn primary-border">Reset password</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
}

export default StartPage