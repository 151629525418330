import React, { useState, createContext } from "react";
import { auth, db } from "./firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Loading from "./components/organisms/loading";

export const UserContext = createContext({
  user: null,
  loading: true
})

export const UserContextProvider = (props) => {
  const [ user, setUser ] = useState(null)
  const [ loading, setLoading ] = useState(true)

  const fetchUser = async (uid) => {
    return onSnapshot(doc(db, "users", uid), (docSnap) => {
      if (docSnap.exists()) {
        setUser(docSnap.data())
      } else {
        setUser(null)
      }
      setLoading(false)
    });
    
    
  }

  React.useEffect(()=> {
    const unsubscribe = auth.onAuthStateChanged((userCredentials)=> {
      if(userCredentials) {
        return fetchUser(userCredentials.uid);
      } else {
        setUser(null)
        setLoading(false)
      }
    })
    return () => unsubscribe();
  }, [])

  return<UserContext.Provider value={{ user, loading}}>
    {loading? <Loading/>: props.children}
  </UserContext.Provider>
}