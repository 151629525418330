import { useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import NavBar from "../../components/organisms/navBar"
import { getFunctions, httpsCallable } from "firebase/functions";

import { API } from "../../constants"
import { functions } from "../../firebase";
import Header from "../../components/organisms/header";

const ContactPage = () => {
  useTitle("Contact Us")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Contact us"}/>
      <ContactForm/>
    </main>
    <Footer/> 
  </div>
}

const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const name = data.get('name')
    const email = data.get('email')
    const message = data.get('message')

    if(!name || !email || !message) {
      setMessage("Fill in all fields")
      return
    }

    setLoading(true)
    setMessage(null)

    const body = {
      name,
      email,
      message
    }

    const contactEmail = httpsCallable(functions, 'contactEmail');

    contactEmail(body).then((result) => {
      setLoading(false)
      setMessage("Inquiry sent to support team. Please allow a few days for us to respond")
      event.target.reset();
    }).catch((error) => {
      setLoading(false)
      setMessage(error.message || "Something went wrong")
    });
  }

  return <section class="contact-section">
    <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="contact-title">Get in Touch</h2>
      </div>
      <div class="col-lg-8">
        <form class="form-contact contact_form" onSubmit={handleSubmit} method="post" id="contactForm" novalidate="novalidate">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder=" Enter Message"></textarea>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input class="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Enter your name"/>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email"/>
              </div>
            </div>
          </div>
          { message && 
            <div class="col-12" style={{background: "#EEE9E9"}}>
            <div class="form-group">
              <p>{message}</p>
            </div>
          </div>
          }
          <div class="form-group mt-3">
            <button type="submit" class="genric-btn danger large" disabled={loading}>{loading? "Sending":"Send"}</button>
          </div>
        </form>
      </div>
      <div class="col-lg-3 offset-lg-1">
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-home"></i></span>
          <div class="media-body">
            <h3>Halfway House, Midrand</h3>
            <p>Gauteng, 1682</p>
          </div>
        </div>
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-tablet"></i></span>
          <div class="media-body">
            <h3>067 811 1602</h3>
            <p>Mon to Fri 9am to 5pm</p>
          </div>
        </div>
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-email"></i></span>
          <div class="media-body">
            <h3>support@tiqevent.app</h3>
            <p>Send us your query anytime!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
}

export default ContactPage