import React, { useContext, useState } from "react";
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import NavBar from "../../components/organisms/navBar"
import { UserContext } from "../../context";
import moment from 'moment';
import { signOut } from 'firebase/auth';
import { auth, db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/organisms/header";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { ticketTypes } from "../../constants";

const ProfilePage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  useTitle("Profile")

  const logout = () => {
    setLoading(true)
    signOut(auth).then(()=> {
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    })
  }

  if(!user) {
    navigate("/")
    return <></>
  }

  return <div>
    <NavBar/>
    <main>
      <Header title={"Profile"}/>
      <ProfileDetails user={user}/>
      <TicketsList user={user}/>
      <EventsList user={user}/>
      <hr/>
    <br/>
    <div class="container">
      <button class="genric-btn danger" onClick={()=> logout()} disabled={loading}>{loading? "Processing": "Logout"}</button>
      <br/><br/><br/>
    </div>
    </main>
    <Footer/> 
  </div>
}

const ProfileDetails = ({user}) => {
  
  return <section class="sample-text-area">
  <div class="container">
    <h3 class="text-heading">{user.names}</h3>
    <p class="sample-text">
      Your email is <b>{user.email}</b>
      <br/>
      { user.accountType === 2 && <>
        Your reg number is <b>{user.id}</b>
        <br/>
      </>
      }
      Your account type is <b>{user.accountType === 1? "Individual": "Organisation"}</b>
      <br/>
      Your account was created at <b>{moment(user.createdAt.toDate()).format('MMMM Do YYYY, HH:mm:ss a')}</b>
    </p>
    <Link to={"/edit"} class="genric-btn primary-border">Edit Profile</Link>
  </div>
</section>
}

const EventsList = ({user}) => {
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])

  const fetchEvents = async () => {
    const q = query(collection(db, "events"), where("userId", "==", auth.currentUser.uid));

    const querySnapshot = await getDocs(q);
    const events = []
    querySnapshot.forEach((doc) => {
      events.push({...doc.data(), id: doc.id})
    });

    setEvents(events)
  }

  const syncEvent = async (event, data) => {
    setLoading(true)
    await updateDoc(doc(db, "events", event.id), {
      status: data
    });
    await fetchEvents()

    setLoading(false)
  }


  React.useEffect(()=> {
    fetchEvents()
  },[user])

  return <section>
    <div class="container">
      <h3 style={{marginBottom: 20}}>My events</h3>
      <div style={{paddingLeft: 0, paddingRight: 0, marginBottom: 30}}>
        Please send us your invoice after your event at support@tiqevent.app and payment shall be made within 14 days.
      </div>
      <div class="progress-table-wrap">
        <div class="progress-table">
          <div class="table-head">
            <div class="percentage" style={{paddingLeft: 15}}>Name</div>
            <div class="percentage">Event Date</div>
            <div class="percentage">Remaining</div>
            <div class="percentage">Sold</div>
            <div class="percentage">Action</div>
          </div>
          {events.map((event)=> (
            <div class="table-row">
              <div class="percentage" style={{paddingLeft: 15}}><Link to={`/event/${event.id}`}>{event.name}</Link></div>
              <div class="percentage">{moment(event.date.toDate()).format('Do MMMM YYYY HH:mm')}</div>
              <div class="percentage">
              <ul>
              { Object.keys(event.tickets).map((type) => {
                if(event.tickets[type].tickets === 0) {
                  return <></>
                }
                return <li>{event.tickets[type].name || type} - {event.tickets[type].remaining}</li>
              })}
              </ul>
              </div>
              <div class="percentage">
              <ul>
              { Object.keys(event.tickets).map((type) => {
                if(event.tickets[type].tickets === 0) {
                  return <></>
                }
                const sold = Number(event.tickets[type].tickets) - Number(event.tickets[type].remaining)
                return <li>{event.tickets[type].name || type} - R{sold * Number(event.tickets[type].price)} ({sold})</li>
              })}
              </ul>
              </div>
              <div class="percentage">
                <button onClick={()=> {
                  syncEvent(event, event.status === 1? 0: 1)
                }}  class={event.status === 1? "genric-btn primary": "genric-btn danger" }>{
                  loading? "Processing": (event.status === 1? "Publish": "Unpublish")
                }</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <br/><br/>
</section>
}


const TicketsList = ({user}) => {
  const [tickets, setTickets] = useState([])
  const fetchTickets = async () => {
    const q = query(collection(db, "tickets"), where("user", "==", auth.currentUser.uid));

    const querySnapshot = await getDocs(q);
    const tickets = []
    querySnapshot.forEach((doc) => {
      tickets.push({...doc.data(), id: doc.id})
    });

    const detailed = []
    for(let index = 0; index < tickets.length; index ++) {
      const ticket = tickets[index]
      const eventRef = doc(db, "events", ticket.event)
      const eventSnap = await getDoc(eventRef);

      detailed.push({
        ...ticket,
        event: eventSnap.data()
      })
    }

    setTickets(detailed)
  }

  React.useEffect(()=> {
    fetchTickets()
  },[user])

  return <section>
    <div class="container">
      <h3 style={{marginBottom: 20}}>My tickets</h3>
      <div class="progress-table-wrap">
        <div class="progress-table">
          <div class="table-head">
            <div class="percentage" style={{paddingLeft: 15}}>Name</div>
            <div class="percentage">Location</div>
            <div class="percentage">Purchase Date</div>
            <div class="percentage">Reference</div>
          </div>
          {tickets.map((ticket)=> (
            <div class="table-row">
              <div class="percentage" style={{paddingLeft: 15}}>{ticket.event.name}</div>
              <div class="percentage">{ticket.event.location}</div>
              <div class="percentage">{moment(ticket.createdAt.toDate()).format('Do MMMM YYYY HH:mm')}</div>
              <div class="percentage"><Link to={`/ticket/${ticket.id}`}>{ticket.id}</Link></div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <br/><br/>
</section>
}


export default ProfilePage