const Loading = () => {
  return <section>
    <div style={styles}>
      <img src = "/tiqeventlogo.png" width={200}></img>
    </div>
  </section>
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
};

export default Loading