import React, { useContext, useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"
import { auth, db, functions } from "../../firebase"
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { Link, useParams } from "react-router-dom"
import moment from "moment"
import { usePaystackPayment } from "react-paystack"
import { UserContext } from "../../context"
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { httpsCallable } from "firebase/functions"
import { ticketTypes } from "../../constants"

const EventPage = (props) => {
  useTitle("Event")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Event"}/>
      <br/><br/>
      <EventsDetails />
    </main>
    <Footer/> 
  </div>
}
const EventsDetails = (props) => {
  const params = useParams()
  const { user } = useContext(UserContext)
  const [ event, setEvent ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ buying, setBuying ] = useState(false)
  const [ reference, setReference ] = useState(null)
  const [ prepared, setPrepared] = useState(null)
  const [ key, setKey] = useState(0)

  const total = () => {
    let totalPrice = 0
    Object.keys(event.tickets).forEach((type) => {
      if(prepared && prepared[type]) {
        totalPrice = totalPrice + (event.tickets[type].price * prepared[type]) 
      }
    })
    return totalPrice
  }

  const initializePayment = usePaystackPayment({
    reference: reference + (auth.currentUser? auth.currentUser.uid: ""),
    email: auth.currentUser? auth.currentUser.email: "",
    amount: event? total() * 100: 100,
    currency: "ZAR",
    //publicKey: "pk_test_1cc71e32ea435a85e8984af8651936c36e55d415",
    publicKey: "pk_live_8cd1894fa52a03109a0a485d7793abaa5a2fbb22",
    channels: ["card"]
  });

  // you can call this function anything
  const onSuccess = (reference) => {
    onCreateTicket(event, reference)
  };

  // you can call this function anything
  const onClose = () => {
    setBuying(false)
  }

  const onCreateTicket = async (event, ref) => {
    const data =  {
      user: auth.currentUser.uid,
      event: event.id,
      reference: ref,
      options: prepared,
      views: 0,
      createdAt: new Date()
    }
   
    await setDoc(doc(db, "tickets", reference), data);

    let tempEvent = event
    for(let index = 0; index < Object.keys(prepared).length; index ++) {
      const ticket =  Object.keys(prepared)[index];
      const remaining = Number(event.tickets[ticket].remaining);
      const ordered = Number(prepared[ticket]);

      const updated = {
        ...event.tickets[ticket]
      }

      tempEvent.tickets[ticket].remaining = remaining - ordered

      await updateDoc(doc(db, "events", event.id), { 
        tickets: {
          ...tempEvent.tickets,
          [ticket]: {
            ...updated,
            remaining: remaining - ordered
          }
        }
      });
    }
    
    const ticketEmail = httpsCallable(functions, 'ticketEmail');

    ticketEmail({
      user,
      ticket: {...data, id: reference},
      event
    }).then((result) => {
      window.location = `/ticket/${reference}`
    }).catch((error) => {
      window.location = `/ticket/${reference}`
    });
  }

  const fetchEvent = async (id) => {
    setLoading(true)
    const ref = doc(db, "events", id)
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      const userRef = doc(db, "users", docSnap.data().userId)
      const userSnap = await getDoc(userRef);
      setEvent({...docSnap.data(), id: docSnap.id, user: userSnap.data()})
    } else {
      setEvent(null)
    }
    setLoading(false)
  }

  const fetchId =  async () => {
    const ref = doc(collection(db, "tickets"));
    setReference(ref.id)
  }

  React.useEffect(()=> {
    fetchEvent(params.id)
    fetchId()
  }, [])

  if(!event || loading) {
    return <div style={{textAlign: "center"}}>
      <p>Loading</p>
      <br/>
      <br/>
    </div>
  }

  let remaining = 0
  Object.keys(event.tickets).forEach((type) => {
    if(event.tickets[type] && event.tickets[type].remaining) {
      remaining = remaining + event.tickets[type].remaining 
    }
  })

  let totalPrice = total()

  return <div class="listing-caption section-padding1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h3 class="mb-20">{event.name}</h3>
          <p class="mb-30">
            {event.description} <br/>
            <hr/>
            <b>Location</b>: {event.location}, {event.province}<br/>
            <b>Date</b>: {moment(event.date.toDate()).format('MMMM Do YYYY HH:mm')} <br/>
            <b>Prices</b>: {  Object.keys(event.tickets).map((type) => {
              if(event.tickets[type].tickets === 0) {
                return ""
              }
              return <span>{event.tickets[type].name || type} (R{event.tickets[type].price}), </span>
            })}
            <br/>
            <b>Organiser</b>: {event.user.names} <br/>
          </p>
          <div style={{marginBottom: 40}}>
            <AddToCalendarButton
              name={event.name}
              startDate={moment(event.date.toDate()).format('YYYY-MM-DD')}
              options={['Apple','Google','Yahoo','iCal']}
              location={event.location}
            />
          </div>
          <div class="list-img">
            <img src={event.image} alt="" style={{width: "40%"}}/>
          </div>
          <br/>
          <p>
            <b>Tickets or tables remaining</b>
            <br/>
            { Object.keys(event.tickets).map((type) => {
              if(event.tickets[type].tickets === 0) {
                return <></>
              }
              return <>- <span>{event.tickets[type].name || type} ({event.tickets[type].remaining}) {event.tickets[type].table? event.tickets[type].description: ""}</span><br/></>
            })}
          </p>

          <hr/>
            { remaining === 0 && 
              <button class="genric-btn default radius" disabled>Sold out</button>
            }
          {
            !auth.currentUser && <div>
              <p>You must first login to buy a ticket <Link to={"/start"}>Sign in or register</Link></p>
            </div>
          }
          {
            auth.currentUser && remaining > 0 && <div style={{paddingTop: 10}}>
              <h6><b>Checkout</b></h6>
              <p>
                Proceed with payment using <a target="_tab" href="http://paystack.com">Paystack</a>, our trusted payment partner.
              </p>
              <br/>
              <p>
              {
                  Object.keys(event.tickets).map((type)=> {
                    if(event.tickets[type].tickets === 0) {
                      return <></>
                    }
                    return <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <p>{event.tickets[type].name || type}</p>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <p>Number of {event.tickets[type].table? "Tables": "Tickets"} (R{event.tickets[type].price})</p>
                          <input 
                            class="form-control valid" name={`${type}_tickets`} id={`${type}_tickets`} type="number" min={0} max={event.tickets[type].tickets} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Number of tickets'" placeholder="Number of tickets" defaultValue={0} 
                            required
                            onChange={(e)=> {
                              if(prepared) {
                                let newPrepared = prepared
                                newPrepared[type] = e.target.valueAsNumber
                                setPrepared(() => newPrepared)
                                setKey(Math.random() * 10000)
                              } else {
                                setPrepared({
                                  [type]: e.target.valueAsNumber
                                } )
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  })
                }
                
              </p>

              <button disabled={!prepared || totalPrice <= 0}  class="genric-btn primary radius" onClick={()=> {
                 setBuying(true)
                 initializePayment(onSuccess, onClose)
                }}>{buying? "Processing": `Pay R${totalPrice}`}</button>
            </div>
          }
          {
            auth.currentUser && event.price === 0 && <div style={{paddingTop: 10}}>
              <h6><b>Checkout</b></h6>
              <p>
                Proceed with getting your free ticket.
              </p>
              <button  class="genric-btn primary radius" onClick={()=> {
                  setBuying(true)
                  onCreateTicket(event, "free")
                }}>{buying? "Processing": `Checkout`}</button>
            </div>
          }
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
}

export default EventPage