import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"

const TermsPage = () => {
  useTitle("Terms and Conditions")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Terms and Conditions"}/>
      <Terms/>
    </main>
    <Footer/> 
  </div>
}

const Terms = () => {
  return <div style = {{paddingTop: 60}} class="about-details section-padding2">
    <div class = "container">
  <h1>Terms and Conditions</h1>

<h2>Agreement to Terms</h2>
<p>By accessing and using our ticketing and events website, you agree to be bound by these Terms and Conditions. If you do
    not agree with any part of these terms, you may not access the website or use our services.</p>

<h2>Ticket Purchase and Refunds</h2>
<p>When purchasing tickets through our website:</p>
<ul>
    <li>All sales are final, and refunds or exchanges are subject to the specific policies of the event organizer.</li>
    <li>We are not responsible for any issues related to ticket delivery or event cancellations.</li>
    <li>Prices and availability of tickets are subject to change without prior notice.</li>
</ul>

<h2>Intellectual Property</h2>
<p>The content on our website, including text, graphics, logos, and images, is protected by intellectual property laws.
    You may not reproduce, distribute, modify, or create derivative works without prior written consent.</p>

<h2>Limitation of Liability</h2>
<p>We strive to provide accurate and reliable information on our website, but we do not guarantee its completeness,
    accuracy, or reliability. We are not liable for any direct, indirect, incidental, or consequential damages resulting
    from your use of our website or services.</p>

<h2>Links to Third-Party Websites</h2>
<p>Our website may contain links to third-party websites that are not owned or controlled by us. We have no control over
    the content, privacy policies, or practices of these websites and disclaim any responsibility for them.</p>

<h2>Modification and Termination</h2>
<p>We reserve the right to modify, suspend, or terminate our website or services at any time without prior notice. We may
    also update these Terms and Conditions from time to time. Continued use of the website after any changes shall
    constitute your consent to such changes.</p>

<h2>Governing Law</h2>
<p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any
    disputes arising out of or related to these terms shall be resolved in the courts of [Your Jurisdiction].</p>

<h2>Contact Us</h2>
<p>If you have any questions or concerns about these Terms and Conditions, please contact us using the contact information
    provided on our website.</p>

    </div>   
  </div>
}

export default TermsPage