import { Link } from 'react-router-dom'
import { categories } from '../../../constants';

const Footer = () => {
  return <footer>
    <div class="footer-area">
      <div class="container">
         <div class="footer-top footer-padding">
              <div class="row justify-content-between">
                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                    <div class="single-footer-caption mb-50">
                      <div class="single-footer-caption mb-30">
                        <div class="footer-logo">
                        <img src = "/tiqeventlogo.png" width={100}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                    <div class="single-footer-caption mb-50">
                      <div class="footer-tittle">
                        <h4>Quick Link</h4>
                        <ul>
                          <li><Link to="/">Home</Link></li>
                          <li><Link to="/events">Events</Link></li>
                          <li><Link to="/about">About</Link></li>
                          <li><Link to="/contact">Contact</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                    <div class="single-footer-caption mb-50">
                      <div class="footer-tittle">
                        <h4>Legal</h4>
                        <ul>
                          <li><Link to="/cookies">Cookies Policy</Link></li>
                          <li><Link to="/privacy">Privacy Policy</Link></li>
                          <li><Link to="/terms">Terms & Conditions</Link></li>
                          <li><Link to="/fees">Fees</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-footer-caption mb-50">
                      <div class="footer-tittle">   
                        <h4>Categories</h4>
                        <ul>
                          {
                            categories.slice(0,5).map((category)=> {
                              return <li><Link to={`/events?category=${category.name}`}>{category.name}</Link></li>
                            })
                          }   
                        </ul>
                      </div>
                    </div>
                  </div>
              </div>
         </div>
          <div class="footer-bottom">
              <div class="row d-flex justify-content-between align-items-center">
                  <div class="col-xl-9 col-lg-8">
                    <div class="footer-copy-right">
                      <p>All rights reserved | This template is made with <i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a></p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-4">
                    <div class="footer-social f-right">
                      <a href="https://www.facebook.com/profile.php?id=100093108726431&mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
                      <a href="https://twitter.com/tiqevent"><i class="fab fa-twitter"></i></a>
                      <a href="https://instagram.com/tiqevent?igshid=NTc4MTIwNjQ2YQ=="><i class="fab fa-instagram"></i></a>
                    </div>
                  </div>
              </div>
         </div>
      </div>
  </div>
</footer>
}
export default Footer;