import { useContext, useState } from "react";
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import NavBar from "../../components/organisms/navBar"
import { UserContext } from "../../context";
import moment from 'moment';
import { signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { auth, db, storage } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import Header from "../../components/organisms/header";
import { serviceProviders, southAfricanProvinces } from "../../constants";
import Select from 'react-select'
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v1 as uuidv1 } from 'uuid';

const EditProfilePage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useTitle("Edit Profile")

  if(!user) {
    navigate("/")
    return <></>
  }

  return <div>
    <NavBar/>
    <main>
      <Header 
        title={"Edit Profile"}
        subtitle={"Are you a Service Provider? Join our community of top-tier event professionals and showcase your expertise to a global audience. Connect with event organizers seeking your skills, whether it’s catering, entertainment, security, decor or stage management."}
      />
      <EditProfileDetails user={user}/>
    </main>
    <Footer/> 
  </div>
}

const EditProfileDetails = ({user}) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [names, setNames] = useState(user.names)
  const [location, setLocation] = useState(user.location)
  const [description, setDescription] = useState(user.description)
  const [province, setProvince] = useState(user.province || southAfricanProvinces[0].name)
  const [status, setStatus] = useState(user.provider? true: false)
  const [categories, setCategories] = useState(user.categories || [])
  const [id, setId] = useState(user.id)
  const [imageAsFile, setImageAsFile] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const names = data.get('names')
    const description = data.get('description')
    const id = data.get('id')

    if(!names) {
      setMessage("Fill in all fields")
      return
    }

    setLoading(true)
    setMessage(null)

    const image = await handleUpload()

    syncProfile(auth.currentUser, {
      names,
      id,
      provider: status? true: false,
      location: location || null,
      province: province || null,
      categories,
      description: description || null,
      image: image || (user.image || null),
      modifiedAt: new Date()
    })
  }

  const handleUpload = async () => {
    if(!imageAsFile) {
      return null
    }

    const ext = imageAsFile.name.split(".")[1]
    const path = `/images/${uuidv1()}.${ext}`;
    const storageRef = ref(storage, path);

    await uploadBytes(storageRef, imageAsFile, {contentType: 'image/jpeg',});

    const url =  await getDownloadURL(storageRef)
    
    return url
  }

  const handleImageAsFile = (e) => {
    const image = e.target.files[0]
    setImageAsFile(image)
  }

  const syncProfile = async (user, data) => {
    await updateProfile(user, {
      displayName: data.names
    })

    console.log(data);
    
    await updateDoc(doc(db, "users", auth.currentUser.uid), data);
    setLoading(false)
    setMessage("Updated successfully")
  }

  return <section class="contact-section">
  <div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="contact-title">Profile</h2>
    </div>
    <div class="col-lg-7">
      <form class="form-contact contact_form" onSubmit={handleSubmit} method="post" id="contactForm" novalidate="novalidate">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <input class="form-control valid" name="email" id="email" type="email" value={user.email} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your email'" placeholder="Enter your email" disabled/>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <input class="form-control valid" name="names" id="names" type="text" value={names} onChange={(e) => { setNames(e.target.value)}} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your names'" placeholder="Enter your names"/>
            </div>
          </div>
          { user.accountType === 2 && 
            <div class="col-sm-12">
              <div class="form-group">
                <input class="form-control valid" name="id" id="text" type="text" value={id} onChange={(e) => { setId(e.target.value)}}  onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your reg number'" placeholder="Enter your reg number"/>
              </div>
            </div>
          }
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <div style={{display: "flex", flexDirection: "row"}}>
            <input class="valid" name="status" id="status" type="checkbox" checked={status} value={status} onChange={(e) => { setStatus(e.target.value)}} />
              <p style={{paddingLeft: 20, marginTop: -10}}>I am service provider</p>
            </div>
          </div>
        </div>
        { status && <div>
          <div class="col-sm-6">
            <div class="form-group">
              <p>Your location</p>
              <input class="form-control valid" name="location" id="location" value={location} onChange={(e) => { setLocation(e.target.value)}} type="location" min={0} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Your location'" placeholder="Your location" required/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <p>Province</p>
              <div class="form-select" id="default-select">
                <select  class="select" value={province} onChange={(event)=> {
                  setProvince(event.target.value)
                }}>
                  {
                    southAfricanProvinces.map((province)=> {
                      return <option value={province.name}>{province.name}</option>
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <p>Profile banner</p>
              <input 
                type="file"
                class="form-control valid"
                onChange={handleImageAsFile}
              />
            </div>
          </div>
        <div class="col-sm-12">
          <div class="form-group">
            <p>Service categories</p>
            <div style={{border: "1px solid silver"}}>
              <Select 
                isMulti={true} 
                value={categories.map((service) => {
                  return {
                    label: service,
                    value: service
                  }
                }) || []}
                onChange={(e)=> (
                  setCategories(e.map((value)=> {
                    return value.label
                  }))
                )}
                options={ serviceProviders.map((service) => {
                  return {
                    label: service.name,
                    value: service.name
                  }
                })}
               />
            </div>
          </div>
        </div>
        <div class="col-sm-12">
            <div class="form-group">
                <textarea rows={3} onChange={(e) => { setDescription(e.target.value)}} class="single-textarea" id="description" name="description" placeholder="Describe your services" required>{description}</textarea>
            </div>
          </div>
        </div>
        }
        { message && 
          <div class="col-12" style={{background: "#EEE9E9"}}>
          <div class="form-group">
            <p>{message}</p>
          </div>
        </div>
        }
        <div class="form-group mt-3">
          <button type="submit" class="genric-btn danger large" disabled={loading}>{loading? "Processing":"Update"}</button>
        </div>
      </form>
    </div>
    <div class="col-lg-4 offset-lg-1">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <Link to="/profile" class="genric-btn primary">Back to Profile</Link>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
</section>
}

export default EditProfilePage