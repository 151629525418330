import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"

const PrivacyPage = () => {
  useTitle("Privacy Policy")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Privacy Policy"}/>
      <Privacy/>
    </main>
    <Footer/> 
  </div>
}

const Privacy = () => {
  return <div style = {{paddingTop: 60}} class="about-details section-padding2">
    <div class = "container">
  <h1>Privacy Policy</h1>

  <p>This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information on our ticketing and
        events website. Please read this policy carefully to understand our practices regarding your personal data.</p>

    <h2>Information we collect</h2>
    <p>We may collect the following types of personal information:</p>
    <ul class="unordered-list">
        <li>Contact information (name, email address, phone number)</li>
        <li>Payment details for ticket purchases</li>
        <li>Event preferences and interests</li>
        <li>Information provided in support requests or inquiries</li>
    </ul>

    <h2>How we use your information</h2>
    <p>We use your personal information to:</p>
    <ul class="unordered-list">
        <li>Process and manage ticket orders</li>
        <li>Send event updates, confirmations, and important notifications</li>
        <li>Personalize your experience on our website</li>
        <li>Respond to your inquiries and provide customer support</li>
        <li>Analyze and improve our website, products, and services</li>
    </ul>

    <h2>Information sharing and disclosure</h2>
    <p>We may share your personal information with:</p>
    <ul class="unordered-list">
        <li>Event organizers for the purpose of managing events and ticketing</li>
        <li>Third-party service providers who assist us in operating our website and delivering services to you</li>
        <li>Law enforcement or government officials when required by law</li>
    </ul>

    <h2>Security of your information</h2>
    <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However,
        please be aware that no data transmission over the internet can be guaranteed to be 100% secure.</p>

    <h2>Retention of your information</h2>
    <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this privacy
        policy, unless a longer retention period is required or permitted by law.</p>

    <h2>Changes to this policy</h2>
    <p>We reserve the right to update or modify this Privacy Policy at any time. Please review this policy periodically for
        any changes.</p>

    <h2>Contact us</h2>
    <p>If you have any questions or concerns about our Privacy Policy, please contact us using the contact information provided
        on our website.</p>


    </div>   
  </div>
}

export default PrivacyPage