const Header = ({title, subtitle}) => {
  return <div class="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="hero-cap text-center pt-50">
            <h2>{title}</h2>
          </div>
        </div>
        { subtitle && <div class="col-xl-12">
          <div class="hero-cap text-center pt-10">
            <h3 style={{color: "white"}}>{subtitle}</h3>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
}

export default Header