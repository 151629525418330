import { useContext, useState } from "react";
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import NavBar from "../../components/organisms/navBar"
import { UserContext } from "../../context";
import { auth, db, storage } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import { categories, southAfricanProvinces, ticketTypes } from "../../constants";
import Header from "../../components/organisms/header";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v1 as uuidv1 } from 'uuid';
import Select from 'react-select'

const AddEventPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useTitle("Add Event")

  if(!user) {
    navigate("/")
    return <></>
  }

  return <div>
    <NavBar/>
    <main>
      <Header 
        title={"Add Event"}
        subtitle={"Plan your event effortlessly , through our user-friendly website application. Sell your tickets with us seamlessly. From selling tickets to managing table booking for exclusive event attendees. Tiqevent also offers a diverse selection of service providers to make your event extraordinary."}
      />
      <AddEventForm user={user}/>
    </main>
    <Footer/> 
  </div>
}

const AddEventForm = ({user}) => {
  const [loading, setLoading] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [message, setMessage] = useState(null)
  const [category, setCategory] = useState(categories[0].name)
  const [tables, setTables] = useState([])
  const [province, setProvince] = useState(southAfricanProvinces[0].name)
  const [imageAsFile, setImageAsFile] = useState(null)
  const [date, setDate] = useState(new Date())

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(loading) return

    const data = new FormData(event.target);
    const name = data.get('name')
    const description = data.get('description')
    const location = data.get('location')
    const date = data.get('date')
    const ticketsOptions = ticketTypes.map((type)=> {
      return {
        type: type,
        tickets: data.get(`${type}_tickets`),
        price: data.get(`${type}_price`)
      }
    })


    const tableOptions = tables.map((type)=> {
      return {
        type: type,
        name: data.get(`${type}_name`),
        description: data.get(`${type}_description`),
        tickets: data.get(`${type}_tickets`),
        price: data.get(`${type}_price`)
      }
    })

    const tickets = {};
    ticketsOptions.forEach((obj) => {
      tickets[obj.type] = {
        name: obj.type,
        table: false,
        tickets: Number(obj.tickets),
        remaining: Number(obj.tickets),
        price: Number(obj.price)
      }
    });

    tableOptions.forEach((obj) => {
      tickets[obj.type] = {
        name: obj.name,
        description: obj.description,
        table: true,
        tickets: Number(obj.tickets),
        remaining: Number(obj.tickets),
        price: Number(obj.price)
      }
    });
    
    if(!name || !description || !location || !date) {
      setMessage("Fill in all fields")
      return
    }

    if(!imageAsFile) {
      setMessage("Image is mandatory")
      return
    }

    setLoading(true)
    setMessage(null)
    const url = await handleUpload()


    syncEvent(event, {
      name, 
      date: date? new Date(date): new Date(), 
      tickets, 
      hasTables: tables.length > 0? true: false,
      description, 
      category,
      province,
      location,
      image: url,
      userId: auth.currentUser.uid,
      createdAt: new Date(),
      modifiedAt: new Date()
    })
  }

  const handleUpload = async (e) => {
    if(!imageAsFile) {
      return null
    }

    const ext = imageAsFile.name.split(".")[1]
    const path = `/images/${uuidv1()}.${ext}`;
    const storageRef = ref(storage, path);

    await uploadBytes(storageRef, imageAsFile, {contentType: 'image/jpeg',});

    const url =  await getDownloadURL(storageRef)
    
    return url
  }

  const handleImageAsFile = (e) => {
    const image = e.target.files[0]
    setImageAsFile(image)
  }

  const syncEvent = async (event, data) => {
    await addDoc(collection(db, "events"), data);
    setLoading(false)
    setMessage("Event added successfully")
    event.target.reset();
    setImageAsFile(null)
  }

  return <section class="contact-section">
  <div class="container">
  <div class="row">

    <div class="col-12">
      <p>Add any type of event for free!</p>
    </div>
    <div class="col-12">
      <h2 class="contact-title">Event</h2>
    </div>
    <div class="col-lg-7">
      <form class="form-contact contact_form" onSubmit={handleSubmit} method="post" id="contactForm" novalidate="novalidate">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <p>Event name</p>
              <input class="form-control valid" name="name" id="name" type="name" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Event name'" placeholder="Event name" required/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <p>Event date</p>
              <input class="form-control valid" name="date" value={date} id="date" type="datetime-local" onChange={(e) => {
                setDate(e.target.value);
              }} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Event date'" placeholder="Event date" required/>
            </div>
          </div>
          <div class="col-sm-12 col-lg-12">
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{flex: 1}}>
                <p>Ticket options</p>
              </div>
              <div>
                <Link 
                  onClick={(e) => {
                    e.preventDefault()
                    setShowOptions(!showOptions)
                  }}
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <span class="contact-info__icon"><i class="ti-arrow-circle-down"></i></span>
                </Link>
              </div>
            </div>
          </div>
          
          {
            showOptions && ticketTypes.map((type)=> (
              <>
                <div class="col-sm-4">
                  <div class="form-group">
                    <p>{type}</p>
                  </div>
                </div>
                
                <div class="col-sm-4">
                  <div class="form-group">
                    <p>Event price (ZAR)</p>
                    <input class="form-control valid" name={`${type}_price`} id={`${type}_price`} type="number" min={1} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Event price (ZAR)'" placeholder="Event price (ZAR)"  defaultValue={0} required/>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <p>Number of tickets</p>
                    <input class="form-control valid" name={`${type}_tickets`} id={`${type}_tickets`} type="number" min={0} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Number of tickets'" placeholder="Number of tickets" defaultValue={0} required/>
                  </div>
                </div>
              </>
            ))
          }

          <div class="col-sm-12 col-lg-12">
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{flex: 1}}>
                <p>Table options</p>
              </div>
              <div>
                <Link 
                  onClick={(e) => {
                    e.preventDefault()
                    setTables(oldArray => [...oldArray, `table_${tables.length + 1}`]);
                  }}
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <span class="contact-info__icon"><i class="ti-plus"></i></span>
                </Link>
              </div>
            </div>
          </div>
        
          {
            tables.map((type, index)=> (
              <>
                <div class="col-sm-4">
                  <div class="form-group">
                    <p>Table name {index + 1}</p>
                    <input class="form-control valid" name={`${type}_name`} id={`${type}_name`} type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Table name'" placeholder="Table name" required/>
                  </div>
                </div>
                
                <div class="col-sm-4">
                  <div class="form-group">
                    <p>Table price (ZAR)</p>
                    <input class="form-control valid" name={`${type}_price`} id={`${type}_price`} type="number" min={1} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Table price (ZAR)'" placeholder="Table price (ZAR)"  defaultValue={0} required/>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <p>Number of tables</p>
                    <input class="form-control valid" name={`${type}_tickets`} id={`${type}_tickets`} type="number" min={0} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Number of tables'" placeholder="Number of tables" defaultValue={0} required/>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <p>Table Description {index + 1}</p>
                    <textarea rows={3} class="single-textarea" name={`${type}_description`} id={`${type}_description`} placeholder="Describe table options" required></textarea>
                  </div>
                </div>
                <hr/>
              </>
            ))
          }
          
          <div class="col-sm-12">
            <div class="form-group">
              <p>Event banner (Select an image of 1024px by 600px) </p>
              <input 
                type="file"
                class="form-control valid"
                onChange={handleImageAsFile}
              />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
                <textarea rows={6} class="single-textarea" id="description" name="description" placeholder="Describe your event and add more information about artists performing / speakers /  MCs etc. You can also add timestamps of who will be performing or speaking and at what time." onfocus="this.placeholder = ''" onblur="this.placeholder = 'Describe your event and add more information about artists performing / speakers /  MCs etc. You can also add timestamps of who will be performing or speaking and at what time.'" required></textarea>
            </div>
          </div>
            <div class="col-sm-6">
              <div class="form-group">
                <p>Event location</p>
                <input class="form-control valid" name="location" id="location" type="location" min={0} onfocus="this.placeholder = ''" onblur="this.placeholder = 'Event address'" placeholder="Event address" required/>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <p>Province</p>
                <div class="form-select" id="default-select">
                  <select  class="select" value={province} onChange={(event)=> {
                    setProvince(event.target.value)
                  }}>
                    {
                      southAfricanProvinces.map((province)=> {
                        return <option value={province.name}>{province.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <p>Event categories</p>
                <div class="form-select" id="default-select">
                  <select class="select"  value={category} onChange={(event)=> {
                    setCategory(event.target.value)
                  }}>
                    {
                      categories.map((category)=> {
                        return <option value={category.name}>{category.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
        </div>
        { message && 
          <div class="col-12" style={{background: "#EEE9E9"}}>
          <div class="form-group">
            <p>{message}</p>
          </div>
        </div>
        }
        <div class="form-group mt-3">
          <button type="submit" class="genric-btn danger large" >{loading? "Processing":"Add Event"}</button>
        </div>
      </form>
    </div>
    <div class="col-lg-4 offset-lg-1">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <Link to="/" class="genric-btn primary">Back to home</Link>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
</section>
}

export default AddEventPage