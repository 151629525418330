import React, { useRef, useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"
import { serviceProviders, southAfricanProvinces } from "../../constants"
import { collection, getDocs, limit, query, where } from "firebase/firestore"
import { db } from "../../firebase"
import moment from "moment"
import { Link } from "react-router-dom"

const ServicesPage = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const path = params.get('category');
  const query = params.get('text');
  const [text, setText] = useState(query? query: null)
  const [category, setCategory] = useState(path? path: "-")
  const [province, setProvince] = useState("-")
  useTitle("Suppliers")
  return <div>
    <NavBar/>
    <main>
      <Header 
        title={"Suppliers"}
        subtitle={"Browse and book a curated selection of top-notch services tailored to your event’s unique need. From music artists, event promoters, hosts, catering, security and many more."}
      />
      <div class="listing-area pt-80 pb-120">
        <div class="container">
          <div class="row">
            <ServiceFilter
              text={text}
              category={category}
              province={province}
              setText={setText}
              setCategory={setCategory}
              setProvince={setProvince}
              onClear={()=>{
                setText("")
                setCategory("-")
                setProvince("-")
              }}
            />
            <ServicesListing
              text={text}
              category={category}
              province={province}
            />
          </div>
        </div>       
      </div>
    </main>
    <Footer/> 
  </div>
}

const ServiceFilter = ({text, setText, category, setCategory, province, setProvince, onClear}) => {
  const inputRef = useRef();

  return <div class="col-xl-4 col-lg-4 col-md-6">
    <div class="row">
      <div class="col-12">
        <div class="small-section-tittle2 mb-45">
          <h4>Advanced Filter</h4>
        </div>
      </div>
      <div class="category-listing mb-50">
        <div class="single-listing">
          <div class="input-form">
            <input type="text" ref={inputRef}  placeholder="What are you looking for?" onKeyUp={(event)=> {
              setText(inputRef.current.value)
            }}/>
          </div>
          <div class="select-job-items1">
            <p>Category</p>
            <select  class="select" value={category} onChange={(event)=> {
              setCategory(event.target.value)
            }}>
              <option value={"-"}>{"All"}</option> {
                serviceProviders.map((category)=> {
                  return <option value={category.name}>{category.name}</option>
                })
              }
            </select>
          </div>
          <br/>
          <br/>
          <div class="select-job-items2">
            <p>Province</p>
            <select  class="select" value={province} onChange={(event)=> {
              setProvince(event.target.value)
            }}>
              <option value={"-"}>{"All"}</option> {
                southAfricanProvinces.map((province)=> {
                  return <option value={province.name}>{province.name}</option>
                })
              }
            </select>
            <br/>
          </div>
        </div>
        <div class="single-listing">
          <button class="btn list-btn mt-20" onClick={()=> {
            inputRef.current.value = ""
            onClear()
          }}>Reset</button>
        </div>
      </div>
    </div>
  </div>
}

const ServicesListing = ({text, category, province}) => {
  const [providers, setProviders] = useState([])
  const fetchProviders = async () => {
    const q = query(collection(db, "users"), limit(1000), where("provider", "==", true));

    const querySnapshot = await getDocs(q);
    const providers = []
    querySnapshot.forEach((doc) => {
      providers.push({...doc.data(), id: doc.id})
    });

    setProviders(providers.filter((e)=> {
      if(text && !e.name.toLowerCase().includes(text.toLowerCase())) {
        return false
      }
      if(category && category.length > 0 && category !== "-" && !e.categories.includes(category)) {
        return false
      }
      if(province && province.length > 0 && province !== "-" && e.province !== province) {
        return false
      }
      return true
    }))
  }

  React.useEffect(()=> {
    fetchProviders()
  }, [text, category, province])

  return <div class="col-xl-8 col-lg-8 col-md-6">
    <div class="row">
      <div class="col-lg-12">
        <div class="count mb-35">
          <span>{providers.length} suppliers are available</span>
        </div>
      </div>
    </div>
    <div class="listing-details-area">
      <div class="container">
        <div class="row">
          {providers.slice(0,100).map((provider)=> {
            return <div class="col-lg-6">
              <div class="single-listing mb-30">
                <div class="list-img">
                  <img src={provider.image} alt="" height={200} style={{objectFit: "cover"}}/>
                </div>
                <div class="list-caption">
                  <h3><Link to={`/supplier/${provider.id}`}>{provider.names}</Link></h3>
                  <p>{provider.province}</p>
                  <div class="list-footer">
                    <div style={{padding: 2,  margin: 2}}>
                      {
                        provider.categories && provider.categories.map((category, index)=> {
                          if(index < provider.categories.length - 1)
                            return category + " · "
                          return category
                        })
                      }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
}

export default ServicesPage