import { useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import NavBar from "../../components/organisms/navBar"
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth, functions } from "../../firebase";
import { Link } from "react-router-dom";
import Header from "../../components/organisms/header";
import { httpsCallable } from "firebase/functions";


const ResetPage = () => {
  useTitle("Reset")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Reset"}/>
      <ResetForm/>
    </main>
    <Footer/> 
  </div>
}


const ResetForm = () => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const email = data.get('email')

    if(!email) {
      setMessage("Fill in all fields")
      return
    }

    setLoading(true)

    const resetPassword = httpsCallable(functions, 'resetPassword');

    resetPassword({
      email
    }).then((result) => {
      setLoading(false)
      alert("Password reset, check your inbox")
    }).catch((error) => {
      setLoading(false)
      alert(error)
    });
  }

  return <section class="contact-section">
    <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="contact-title">Reset</h2>
      </div>
      <div class="col-lg-7">
        <form class="form-contact contact_form" onSubmit={handleSubmit} method="post" id="contactForm" novalidate="novalidate">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <input class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your email'" placeholder="Enter your email"/>
              </div>
            </div>
          </div>
          { message && 
            <div class="col-12" style={{background: "#EEE9E9"}}>
            <div class="form-group">
              <p>{message}</p>
            </div>
          </div>
          }
          <div class="form-group mt-3">
            <button type="submit" class="genric-btn danger large" disabled={loading}>{loading? "Processing":"Reset password"}</button>
          </div>
        </form>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <Link to="/start" class="genric-btn primary-border">Back to Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
}

export default ResetPage