import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"

const FeesPage = () => {
  useTitle("Fees")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Fees"}/>
      <Fees/>
    </main>
    <Footer/> 
  </div>
}

const Fees = () => {
  return <div style = {{paddingTop: 60}} class="about-details section-padding2">
    <div class = "container">
      <p>Plan your event effortlessly , through our user-friendly website application. Sell your tickets with us seamlessly. From selling tickets to managing table booking for exclusive event attendees. Tiqevent also offers a diverse selection of service providers to make your event extraordinary</p>
      <p>Add any type of event for free!</p>
      <p>Fees are only charged when selling your event tickets through our platform and at a very low price. Other additional payments like cooler box tickets and table booking are also charged with the same price. A unique QR code is provided to each event ticket purchased and it is also provided to event attendees that decides to purchase cooler box tickets and book a table on our platform. If a user decides to purchase event tickets, cooler box tickets and book a table for an event all at once, only one unique QR code will be provided and must be scanned at the entrance of the event to verify everything purchased via our platform.</p>
      <p>
        <b>Fees = 3%</b> of each ticket price goes to Paystack, our payment gateway system that provides secure and safe transactions through our platform. <br/>
        = R5 per ticket 
        = 2% of the total price of all tickets(including total of all cooler box tickets and total of all table bookings) sold through our platform per event, if total price is R50 000 or more. 
      </p>
      <p>
      All these costs are for banking, card processing, pay-outs and support.
      </p>
  </div>
  </div>
}

export default FeesPage