import React from 'react';

const useTitle = (title) => {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} — Tiq Events`;

    return () => {
      document.title = prevTitle;
    };
  }, []);
}
export default useTitle;
