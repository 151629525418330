import React, { useRef, useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"
import { categories, southAfricanProvinces, ticketTypes } from "../../constants"
import { collection, getDocs, limit, query } from "firebase/firestore"
import { db } from "../../firebase"
import moment from "moment"
import { Link } from "react-router-dom"

const EventsPage = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const path = params.get('category');
  const query = params.get('text');
  const [text, setText] = useState(query? query: null)
  const [category, setCategory] = useState(path? path: "-")
  const [province, setProvince] = useState("-")
  useTitle("Events")
  return <div>
    <NavBar/>
    <main>
      <Header 
        title={"Events"}
        subtitle={"Discover upcoming events, book tickets and create unforgettable memories"}
      />
      <div class="listing-area pt-80 pb-120">
        <div class="container">
          <div class="row">
            <EventFilter
              text={text}
              category={category}
              province={province}
              setText={setText}
              setCategory={setCategory}
              setProvince={setProvince}
              onClear={()=>{
                setText("")
                setCategory("-")
                setProvince("-")
              }}
            />
            <EventsListing
              text={text}
              category={category}
              province={province}
            />
          </div>
        </div>       
      </div>
    </main>
    <Footer/> 
  </div>
}

const EventFilter = ({text, setText, category, setCategory, province, setProvince, onClear}) => {
  const inputRef = useRef();

  return <div class="col-xl-4 col-lg-4 col-md-6">
    <div class="row">
      <div class="col-12">
        <div class="small-section-tittle2 mb-45">
          <h4>Advanced Filter</h4>
        </div>
      </div>
      <div class="category-listing mb-50">
        <div class="single-listing">
          <div class="input-form">
            <input type="text" ref={inputRef}  placeholder="What are you looking for?" onKeyUp={(event)=> {
              setText(inputRef.current.value)
            }}/>
          </div>
          <div class="select-job-items1">
            <p>Category</p>
            <select  class="select" value={category} onChange={(event)=> {
              setCategory(event.target.value)
            }}>
              <option value={"-"}>{"All"}</option> {
                categories.map((category)=> {
                  return <option value={category.name}>{category.name}</option>
                })
              }
            </select>
          </div>
          <br/>
          <br/>
          <div class="select-job-items2">
            <p>Province</p>
            <select  class="select" value={province} onChange={(event)=> {
              setProvince(event.target.value)
            }}>
              <option value={"-"}>{"All"}</option> {
                southAfricanProvinces.map((province)=> {
                  return <option value={province.name}>{province.name}</option>
                })
              }
            </select>
            <br/>
          </div>
        </div>
        <div class="single-listing">
          <button class="btn list-btn mt-20" onClick={()=> {
            inputRef.current.value = ""
            onClear()
          }}>Reset</button>
        </div>
      </div>
    </div>
  </div>
}

const EventsListing = ({text, category, province}) => {
  const [events, setEvents] = useState([])
  const fetchEvents = async () => {
    const q = query(collection(db, "events"), limit(1000));

    const querySnapshot = await getDocs(q);
    const events = []
    querySnapshot.forEach((doc) => {
      events.push({...doc.data(), id: doc.id})
    });

    setEvents(events.filter((e)=> {
      if(text && !e.name.toLowerCase().includes(text.toLowerCase())) {
        return false
      }
      if(category && category.length > 0 && category !== "-" && e.category !== category) {
        return false
      }
      if(province && province.length > 0 && province !== "-" && e.province !== province) {
        return false
      }
      
      if(e.status === 1) {
        return false
      }
      return true
    }))
  }

  React.useEffect(()=> {
    fetchEvents()
  }, [text, category, province])

  return <div class="col-xl-8 col-lg-8 col-md-6">
    <div class="row">
      <div class="col-lg-12">
        <div class="count mb-35">
          <span>{events.length} events are available</span>
        </div>
      </div>
    </div>
    <div class="listing-details-area">
      <div class="container">
        <div class="row">
          {events.slice(0,100).map((event)=> {
            let remaining = 0
            Object.keys(event.tickets).forEach((type) => {
              if(event.tickets[type] && event.tickets[type].remaining) {
                remaining = remaining + event.tickets[type].remaining 
              }
            })
            return <div class="col-lg-6">
              <div class="single-listing mb-30">
                <div class="list-img">
                  <img src={event.image} alt="" height={200} style={{objectFit: "cover"}}/>
                </div>
                <div class="list-caption">
                  <span>{remaining} tickets</span>
                  <h3><Link to={`/event/${event.id}`}>{event.name}</Link></h3>
                  <p>{event.location}</p>
                  <div class="list-footer">
                      <ul>
                        <li>R{event.tickets["General"].price}</li>
                        <li>{moment(event.date.toDate()).format('MMMM Do YYYY')}</li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
}

export default EventsPage