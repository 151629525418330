import './assets/css/style.css';
import './assets/css/animate.min.css';
import './assets/css/bootstrap.min.css';

import './assets/css/responsive.css';
import './assets/css/slick.css';
import './assets/css/slicknav.css';

import './assets/css/magnific-popup.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/flaticon.css';
import './assets/css/nice-select.css';
import './assets/css/themify-icons.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/home';
import AboutPage from './pages/about';
import CategoriesPage from './pages/categories';
import EventsPage from './pages/events';
import ContactPage from './pages/contacts';
import StartPage from './pages/start';
import RegisterPage from './pages/register';
import ResetPage from './pages/reset';
import { UserContextProvider } from './context';
import ProfilePage from './pages/profile';
import ScrollToTop from './components/atoms/scrollToTop';
import EditProfilePage from './pages/editProfile';
import AddEventPage from './pages/add';
import EventPage from './pages/event';
import CookiesPage from './pages/cookies';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms';
import TicketPage from './pages/ticket';
import ServicesPage from './pages/services';
import ProviderPage from './pages/provider';
import FeesPage from './pages/fees';

function App() {
  return (
    <UserContextProvider value={null}>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<HomePage/>}/>   

          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/categories" element={<CategoriesPage/>}/>
          <Route path="/events" element={<EventsPage/>}/>
          <Route path="/suppliers" element={<ServicesPage/>}/>
          <Route path="/event/:id" element={<EventPage/>}/>
          <Route path="/ticket/:id" element={<TicketPage/>}/>
          <Route path="/supplier/:id" element={<ProviderPage/>}/>
          <Route path="/contact" element={<ContactPage/>}/>  

          <Route path="/start" element={<StartPage/>}/>
          <Route path="/register" element={<RegisterPage/>}/>
          <Route path="/forgot" element={<ResetPage/>}/>
          <Route path="/cookies" element={<CookiesPage/>}/>
          <Route path="/privacy" element={<PrivacyPage/>}/>
          <Route path="/fees" element={<FeesPage/>}/>
          <Route path="/terms" element={<TermsPage/>}/>


          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/edit" element={<EditProfilePage/>}/>

          <Route path="/add" element={<AddEventPage/>}/>

        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
