import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"

const AboutPage = () => {
  useTitle("About Us")
  return <div>
    <NavBar/>
    <main>
      <Header title={"About us"}/>
      <AboutMission/>
    </main>
    <Footer/> 
  </div>
}

const AboutMission = () => {
  return <div class="about-details section-padding2">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-10">
          <div class="section-tittle section-tittle5 mb-80">
            <span>About our company</span>
            <h2>Unlock the vibrant tapestry of South African events!</h2>
          </div> 
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-lg-4">
          <h3>Mission</h3>
          <p>Our mission is to connect individuals with the rich and diverse tapestry of events in South Africa. We strive to be the premier online platform, offering a seamless and convenient ticket purchasing experience for a wide range of captivating cultural, entertainment, and sporting events across the nation.</p>
        </div>
        <div class="col-lg-5">
          <h3>Vision</h3>
          <p>Our vision is to be the foremost online destination for discovering, accessing, and experiencing the incredible tapestry of events in South Africa. We envision a platform that seamlessly connects event organizers, artists, performers, and enthusiasts, fostering a vibrant community that celebrates the diverse cultural, artistic, and sporting heritage of this magnificent nation.</p>
        </div>
      </div>
    </div>
  </div>
}

export default AboutPage