import { Link } from 'react-router-dom'
import { UserContext } from '../../../context';
import { useContext, useState } from 'react';
import { slide as Menu } from 'react-burger-menu'

const NavBar = () => {
  const { user, userObject } = useContext(UserContext);
  const [ open, setOpen] = useState(false)
  return <header>
    <div class="header-area header-transparent">
      <div class="main-header">
        <div class="header-bottom  header-sticky">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-xl-2 col-lg-2 col-md-1">
                <div class="logo">
                  <Link to="/"><img src = "/tiqeventlogo.png" width={100}></img></Link>
                </div>
              </div>
              <div class="col-xl-10 col-lg-10 col-md-8">
                <div class="main-menu f-right d-none d-lg-block">
                  <nav>
                    <ul id="navigation">                                                                                                                                     
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/about">About</Link></li>
                      <li><Link to="/categories">Categories</Link></li>
                      <li><Link to="/events">Events</Link></li>
                      <li><Link to="/suppliers">Book Service</Link></li>
                      <li><Link to="/contact">Contact</Link></li>
                      <li class="add-list">
                        <Link to={user? "/add": "/start"}><i class="ti-plus"></i> add event</Link>
                      </li>
                      <li class="login">
                        { user && <Link to="/profile">
                          <i class="ti-user"></i> {user.names.slice(0, 30)}
                        </Link>
                        }
                        { !user && <Link to="/start">
                          <i class="ti-user"></i> Sign in or Register
                        </Link>
                        }
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-12">
                <div class="mobile_menu d-block d-lg-none" style={{textAlign: "center", fontSize: 18}}>
                <Menu isOpen={open} right width={"100%"}>
                  <Link to="/">Home</Link>
                  <Link to="/categories">Categories</Link>
                  <Link to="/events">Events</Link>
                  <Link to="/suppliers">Book Service</Link>
                  <Link to="/contact">Contact</Link>
                  <Link to={user? "/add": "/start"}><i class="ti-plus"></i> add event</Link>
                  { user && <Link to="/profile">{user.names.slice(0, 30)}</Link>}
                   { !user && <Link to="/start">Sign in or Register</Link>}
                </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
}
export default NavBar;