import React, { useContext, useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"
import { auth, db, functions } from "../../firebase"
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { Link, useParams } from "react-router-dom"
import moment from "moment"
import { UserContext } from "../../context"
import { httpsCallable } from "firebase/functions"

const ProviderPage = (props) => {
  useTitle("Supplier")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Supplier"}/>
      <br/><br/>
      <ProviderDetails />
    </main>
    <Footer/> 
  </div>
}
const ProviderDetails = (props) => {
  const params = useParams()
  const { user } = useContext(UserContext)
  const [ provider, setProvider ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ buying, setBuying ] = useState(false)
  const [ message, setMessage ] = useState(null)
  const [ description, setDescription ] = useState(null)

  const onEmailTrigger = async () => {
    setBuying(true)
    setMessage(null)
    const data =  {
      customer: {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
        names: auth.currentUser.displayName
      },
      supplier: {
        id: provider.id,
        names: provider.names,
        email: provider.email
      },
      message: description
    }

    const supplierContactEmail = httpsCallable(functions, 'supplierContactEmail');

    supplierContactEmail(data).then((result) => {
      setBuying(false)
      setMessage("We have forwarded your information to the service provider. Please allow a few days for them to get back to you.")
    }).catch((error) => {
      setBuying(false)
      setMessage(error.message || "Something went wrong")
    });
  }

  const fetchUser = async (id) => {
    setLoading(true)
    const ref = doc(db, "users", id)
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      setProvider({id: docSnap.id, ...docSnap.data()})
    } else {
      setProvider(null)
    }
    setLoading(false)
  }

  React.useEffect(()=> {
    fetchUser(params.id)
  }, [])

  if(!provider || loading) {
    return <div style={{textAlign: "center"}}>
      <p>Loading</p>
      <br/>
      <br/>
    </div>
  }

  return <div class="listing-caption section-padding1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h3 class="mb-20">{provider.names}</h3>
          <p class="mb-30">
            {provider.description} <br/>
            <hr/>
            <b>Location</b>: {provider.location}, {provider.province}<br/><br/>
            <b>Services</b>: 
              <ul class="unordered-list">
              {
              provider.categories && provider.categories.map((category)=> (
                <li>{category}</li>
              ))
              }
              </ul>
          </p>
          <div class="list-img">
            <img src={provider.image} alt="" style={{width: "40%"}}/>
          </div>
          <br/>
            <div class="form-group">
              <textarea rows={6} class="single-textarea" onChange={(e) => { setDescription(e.target.value)}} id="description" name="description" placeholder="Describe the service you require" required>{description}</textarea>
            </div>
          <hr/>
          { message && 
            <div class="col-12" style={{background: "#EEE9E9"}}>
            <div class="form-group">
              <p>{message}</p>
            </div>
          </div>
          }
           {
            !auth.currentUser && <div>
              <p>You must first login to request a service <Link to={"/start"}>Sign in or register</Link></p>
            </div>
          }
            { 
              auth.currentUser && <button class="genric-btn primary radius" onClick={()=> onEmailTrigger()}>{buying? "Processing": "Contact Supplier"}</button>
            }
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
}

export default ProviderPage