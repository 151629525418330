import { Link } from "react-router-dom"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"
import { categories } from "../../constants"

const CategoriesPage = () => {
  useTitle("Categories")
  return <div>
    <NavBar/>
    <main>
      <Header 
        title={"Categories"}
        subtitle={"Discover, book and attend a diverse range of events. From Music events, conferences, sport, kids events or any special occasion."}
      />
      <Categories/>
    </main>
    <Footer/> 
  </div>
}

const Categories = () => {
  return <div class="categories-area section-padding30"><div class="container">
  <div class="row">
      <div class="col-lg-12">
          <div class="section-tittle text-center mb-80">
              <span>We are offering for you</span>
              <h2>Featured Categories</h2>
          </div>
      </div>
  </div>
  <div class="row">
    {
      categories.map((category)=> {
        return <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-cat text-center mb-50">
            <div class="cat-icon">
              <span class="flaticon-bed"></span>
            </div>
            <div class="cat-cap">
                <h5><Link to={`/events?category=${category.name}`}>{category.name}</Link></h5>
                <p>{category.description}</p>
                <Link to={`/events?category=${category.name}`}>View Details</Link>
            </div>
        </div>
    </div>
      })
    }
    </div>
  </div>
  </div>
}

export default CategoriesPage