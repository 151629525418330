import useTitle from "../../components/atoms/useTitle";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navBar";
import { Link, useNavigate } from "react-router-dom"
import { categories, ticketTypes } from "../../constants";
import React, { useRef, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import moment from "moment";
const HomePage = () => {
  useTitle("Home")

  return <div>
    <NavBar/>
    <main>
      <HomeHeader/>
      <HomePopularEvents/>
    </main>
    <Footer/> 
  </div>
}

const HomeHeader = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [category, setCategory] = useState("")

  return <div class="slider-area hero-overly">
    <div class="single-slider hero-overly slider-height d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          
          <div class="col-xl-8 col-lg-9">
            <div style={{color: "white", marginBottom: 60, textAlign: "center"}}>
              <h1>Welcome to Tiqevent</h1>
              <h2>Your All-in-One Event Solution! </h2>
              <h3 style={{fontWeight: "normal"}}>Discover seamless ticketing and service booking for your dream event. From tickets to top-tier services, we've got you covered.</h3>
            </div>
            <form action="#" class="search-box">
              <div class="input-form">
                <input type="text" name="search" ref={inputRef} placeholder="What are you looking for?"/>
              </div>
             <div class="select-form">
                <div style={{padding: 10, paddingTop: 20, display: "flex", alignContent: "center"}}>
                  <select class="select" value={category} onChange={(event)=> {
                      setCategory(event.target.value)
                    }}>
                    <option value="">All Categories</option> {
                      categories.map((category)=> {
                        return <option value={category.name}>{category.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
            
              <div class="search-form">
                <Link onClick={(e)=> {
                  e.preventDefault()
                  navigate(`events?category=${category}&text=${inputRef.current?inputRef.current.value:""}`)
                }}>Search</Link>
              </div>	
            </form>	
          </div>
        </div>
      </div>
    </div>
  </div>
}

const HomePopularEvents = () => {
  const [events, setEvents] = useState([])

  const fetchEvents = async () => {
    const q = query(collection(db, "events"), limit(100), orderBy("createdAt", "desc"));

    const querySnapshot = await getDocs(q);
    const events = []
    querySnapshot.forEach((doc) => {
      if(doc.data().status !== 1) {
        events.push({...doc.data(), id: doc.id})
      }
    });
    setEvents(events)
  }

  React.useEffect(()=> {
    fetchEvents()
  }, [])

  return <div class="popular-location section-padding30">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-tittle text-center mb-80">
          <span>Most visited</span>
          <h2>Popular events</h2>
        </div>
      </div>
    </div>
    <div class="listing-details-area">
    <div class="row">
      {
        events.slice(0,3).map((event)=> {
          let remaining = 0
          Object.keys(event.tickets).forEach((type) => {
            if(event.tickets[type] && event.tickets[type].remaining) {
              remaining = remaining + event.tickets[type].remaining 
            }
          })

          return <div class="col-lg-4">
            <div class="single-listing mb-30">
              <div class="list-img">
                <img src={event.image} alt="" height={200} style={{objectFit: "cover"}}/>
              </div>
              <div class="list-caption">
                  <span>{remaining} tickets</span>
                  <h3><Link to={`/event/${event.id}`}>{event.name}</Link></h3>
                  <p>{event.location}</p>
                  <div class="list-footer">
                      <ul>
                        <li>R{event.tickets["General"].price}</li>
                        <li>{moment(event.date.toDate()).format('MMMM Do YYYY')}</li>
                      </ul>
                  </div>
              </div>
            </div>
          </div>
        })
      }
    </div>
    </div>
    <div class="row justify-content-center">
      <div class="room-btn pt-20">
        <Link to="/events" class="btn view-btn1">View all events</Link>
      </div>
    </div>
  </div>
</div>
}

export default HomePage;