import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"

const CookiesPage = () => {
  useTitle("Cookies Policy")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Cookies Policy"}/>
      <Cookies/>
    </main>
    <Footer/> 
  </div>
}

const Cookies = () => {
  return <div style = {{paddingTop: 60}}class="about-details section-padding1">
    <div class = "container">
  <h1>Cookies Policy</h1>

    <p>This Cookies Policy explains what cookies are, how we use them, and how you can control them. Please read this policy
        carefully to understand how we use cookies on our ticketing and events website.</p>

    <h2>What are cookies?</h2>
    <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are widely
        used to make websites work more efficiently and enhance the user experience.</p>

    <h2>How we use cookies</h2>
    <p>We use cookies to:</p>
    <ul class="unordered-list">
        <li>Provide you with a personalized browsing experience.</li>
        <li>Remember your preferences and settings.</li>
        <li>Track your interactions with our website for analytical purposes.</li>
        <li>Improve the performance and security of our website.</li>
    </ul>

    <h2>Types of cookies we use</h2>
    <p>We use the following types of cookies:</p>
    <ul>
        <li>Essential cookies: These are necessary for the website to function properly and cannot be disabled.</li>
        <li>Analytical/performance cookies: These cookies help us analyze how users interact with our website and improve its
            performance.</li>
        <li>Functionality cookies: These cookies allow the website to remember your preferences and provide enhanced
            functionality.</li>
        <li>Advertising/targeting cookies: These cookies are used to deliver personalized advertisements based on your
            interests.</li>
    </ul>

    <h2>Controlling cookies</h2>
    <p>You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you
        can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some
        preferences every time you visit our website, and some services and functionalities may not work.</p>

    <h2>Changes to this policy</h2>
    <p>We may update this Cookies Policy from time to time. We encourage you to review this policy periodically for any
        changes.</p>

    <p>If you have any questions about our Cookies Policy, please contact us.</p>
    </div>   
  </div>
}

export default CookiesPage