// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVm4PeYGcbo09NojjLLCb1YuQi8vYDDqo",
  authDomain: "tiqevent.firebaseapp.com",
  projectId: "tiqevent",
  storageBucket: "tiqevent.appspot.com",
  messagingSenderId: "945138463214",
  appId: "1:945138463214:web:6e082f07e18f2bf2559eff",
  measurementId: "G-NFHTJ4PFK5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

if(process.env.NODE_ENV === "development") {
  connectFunctionsEmulator( functions, "localhost", 5001);
}