import React, { useContext, useState } from "react"
import useTitle from "../../components/atoms/useTitle"
import Footer from "../../components/organisms/footer"
import Header from "../../components/organisms/header"
import NavBar from "../../components/organisms/navBar"
import { auth, db } from "../../firebase"
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore"
import { Link, useParams } from "react-router-dom"
import moment from "moment"
import { usePaystackPayment } from "react-paystack"
import { UserContext } from "../../context"
import QRCode from "react-qr-code";
import { AddToCalendarButton } from "add-to-calendar-button-react"
import { ticketTypes } from "../../constants"

const TicketPage = (props) => {
  useTitle("Ticket")
  return <div>
    <NavBar/>
    <main>
      <Header title={"Ticket"}/>
      <br/><br/>
      <TicketDetails />
    </main>
    <Footer/> 
  </div>
}
const TicketDetails = (props) => {
  const params = useParams()
  const [ ticket, setTicket ] = useState(null)
  const [ loading, setLoading ] = useState(true)

  const fetchTicket = async (id) => {
    setLoading(true)
    const ref = doc(db, "tickets", id)
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      const ticketRef = doc(db, "events", docSnap.data().event)
      const ticketSnap = await getDoc(ticketRef);
      const userRef = doc(db, "users", docSnap.data().user)
      const userSnap = await getDoc(userRef);
      setTicket({...docSnap.data(), id: docSnap.id, event: ticketSnap.data(), user: userSnap.data()})
    } else {
      setTicket(null)
    }
    setLoading(false)
  }

  React.useEffect(()=> {
    fetchTicket(params.id)
  }, [])

  if(!ticket || loading) {
    return <div style={{textAlign: "center"}}>
      <p>Loading</p>
      <br/>
      <br/>
    </div>
  } else if(!ticket && !loading) {
    <div style={{textAlign: "center"}}>
      <p>Not found</p>
      <br/>
      <br/>
    </div>
  }

  return <div class="listing-caption section-padding1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h3 class="mb-20">{ticket.name}</h3>
          
          <p class="mb-30">
            <h5><b>Event details</b></h5>
            <b>Event name</b>: {ticket.event.name}<br/>
            <b>Event location</b>: {ticket.event.location}<br/>
            <b>Event date</b>: {moment(ticket.event.date.toDate()).format('Do MMMM YYYY HH:mm')}<br/>
          </p>
          <div style={{marginBottom: 40}}>
            <AddToCalendarButton
              name={ticket.event.name}
              startDate={moment(ticket.event.date.toDate()).format('YYYY-MM-DD')}
              location={ticket.event.location}
              options={['Apple','Google','Yahoo','iCal']}
            />
          </div>
          <p class="mb-30">
            <h5><b>Ticket details</b></h5>
            <b>Full names</b>: {ticket.user.names} <br/>
            <b>Ticket purchase date</b>: {moment(ticket.createdAt.toDate()).format('Do MMMM YYYY HH:mm')} <br/>
            <b>Ticket reference</b>: {ticket.id} <br/>
            <b>Ticket valid</b>: Yes <br/>
            <b>Tickets or tables</b>: <br/>{  Object.keys(ticket.event.tickets).map((type) => {
              if(ticket.event.tickets[type].tickets === 0) {
                return <></>
              }
              return <>- <span>{ticket.event.tickets[type].name || type} ({ticket.options[type]} {ticket.event.tickets[type].table? " table": " people"})</span><br/></>
            })}
            <br/>
          </p>
            <QRCode
              size={100}
              style={{ height: 200, maxWidth: "100%", width: "100%" }}
              value={`http://tiqevent.app/ticket/${ticket.id}`}/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
}

export default TicketPage